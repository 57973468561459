import { Col, Container, Image, Row, Text } from '@nextui-org/react';
import { TickImage, XImage, } from '../../assets/images';
import { styles } from '../../styles';
export const Pricing = ({isMobile}) => {
    return(
    <div id="Pricing" style={{backgroundColor: '#EEF3F8', paddingTop: 30, paddingBottom: 40}}>
        <Container gap={0}>
            <Text h1 color={styles.colors.primary}>
              Choose the plan that’s right for you
            </Text>
            <Text h3 style={{fontWeight: '400',}} color={styles.colors.primary}>
              Take <b>control</b> of your portfoilio. Management made easy.
            </Text>
            <Row wrap={isMobile() ? 'wrap' : 'nowrap'} >
              <Col style={{
                margin: 20,
                borderRadius: 15,
                MozBoxShadow: '1px 2px 9px #F4AAB9',
                boxShadow: '1px 2px 9px #F4AAB9',
                backgroundColor: '#fff',
              }}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                  <Text h1 color="#B6BBBA" css={{marginLeft: 30}}>Starter</Text>
                  <Text h2 css={{marginLeft: 30, marginTop: -20}}>Free</Text>
                  <Text css={isMobile() ? {margin: 10, textAlign: 'left', marginLeft: 30, marginTop: -10} : { marginLeft: 30, marginTop: -10, width: 400, textAlign: 'left'}}>
                    Our free starter pack is designed for first time landlords taking contol.
                  </Text>
                  <Container gap={0}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                      <Row>
                        <Col css={{width: 100}}>
                          <Image alt="TickImage" src={TickImage} width={25}/>
                        </Col>
                        <Col>
                          <Text css={{textAlign: 'left', marginTop: '5px'}}>1 Property</Text>
                        </Col>
                      </Row>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                      <Row>
                        <Col css={{width: 100}}>
                          <Image alt="TickImage" src={TickImage} width={25}/>
                        </Col>
                        <Col>
                          <Text css={{textAlign: 'left', marginTop: '5px'}}><b>Unlimited</b> Tenants</Text>
                        </Col>
                      </Row>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                      <Row>
                        <Col css={{width: 100}}>
                          <Image alt="XImage" src={XImage} width={25}/>
                        </Col>
                        <Col>
                          <Text css={{textAlign: 'left', marginTop: '5px'}}>Rent Payments</Text>
                        </Col>
                      </Row>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                      <Row>
                        <Col css={{width: 100}}>
                          <Image alt="XImage" src={XImage} width={25}/>
                        </Col>
                        <Col>
                          <Text css={{textAlign: 'left', marginTop: '5px'}}>Multi Messaging</Text>
                        </Col>
                      </Row>
                    </div>
                  </Container>
                </div>
              </Col>
              <Col style={{
                margin: 20,
                borderRadius: 15,
                MozBoxShadow: '1px 2px 9px #F4AAB9',
                boxShadow: '1px 2px 9px #F4AAB9',
                backgroundColor: '#fff',
              }}>
                 <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                  <Text h1 color="#B6BBBA" css={{marginLeft: 30}}>Standard</Text>
                  <Text h2 css={{marginLeft: 30, marginTop: -20}}>£29.99/mo</Text>
                  <Text css={isMobile() ? {margin: 10, textAlign: 'left', marginLeft: 30, marginTop: -10} : { marginLeft: 30, marginTop: -10, width: 400, textAlign: 'left'}}>
                    Our standard pack is designed for our landlords with greater need.
                  </Text>
                  <Container gap={0}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                      <Row>
                        <Col css={{width: 100}}>
                          <Image alt="TickImage" src={TickImage} width={25}/>
                        </Col>
                        <Col>
                          <Text css={{textAlign: 'left', marginTop: '5px'}}>2-10 Properties</Text>
                        </Col>
                      </Row>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                      <Row>
                        <Col css={{width: 100}}>
                          <Image alt="TickImage" src={TickImage} width={25}/>
                        </Col>
                        <Col>
                          <Text css={{textAlign: 'left', marginTop: '5px'}}><b>Unlimited</b> Tenants</Text>
                        </Col>
                      </Row>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                      <Row>
                        <Col css={{width: 100}}>
                          <Image alt="TickImage" src={TickImage} width={25}/>
                        </Col>
                        <Col>
                          <Text css={{textAlign: 'left', marginTop: '5px'}}>Rent Payments</Text>
                        </Col>
                      </Row>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                      <Row>
                        <Col css={{width: 100}}>
                          <Image alt="TickImage" src={TickImage} width={25}/>
                        </Col>
                        <Col>
                          <Text css={{textAlign: 'left', marginTop: '5px'}}>Multi Messaging</Text>
                        </Col>
                      </Row>
                    </div>
                  </Container>
                 </div>
              </Col>
              <Col style={{
                margin: 20,
                borderRadius: 15,
                MozBoxShadow: '1px 2px 9px #F4AAB9',
                boxShadow: '1px 2px 9px #F4AAB9',
                backgroundColor: '#fff',
              }}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                  <Text h1 color="#B6BBBA" css={{marginLeft: 30}}>Premium</Text>
                  <Text h2 css={{marginLeft: 30, marginTop: -20}}>£99.99/mo</Text>
                  <Text css={isMobile() ? {margin: 10, textAlign: 'left', marginLeft: 30, marginTop: -10} : { marginLeft: 30, marginTop: -10, width: 400, textAlign: 'left'}}>
                    Our permium pack is designed for let agencies with greater need.
                  </Text>
                  <Container gap={0}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                      <Row>
                        <Col css={{width: 100}}>
                          <Image alt="TickImage" src={TickImage} width={25}/>
                        </Col>
                        <Col>
                          <Text css={{textAlign: 'left', marginTop: '5px'}}><b>Unlimited</b> Properties</Text>
                        </Col>
                      </Row>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                      <Row>
                        <Col css={{width: 100}}>
                          <Image alt="TickImage" src={TickImage} width={25}/>
                        </Col>
                        <Col>
                          <Text css={{textAlign: 'left', marginTop: '5px'}}><b>Unlimited</b> Tenants</Text>
                        </Col>
                      </Row>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                      <Row>
                        <Col css={{width: 100}}>
                          <Image alt="TickImage" src={TickImage} width={25}/>
                        </Col>
                        <Col>
                          <Text css={{textAlign: 'left', marginTop: '5px'}}>Rent Payments</Text>
                        </Col>
                      </Row>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                      <Row>
                        <Col css={{width: 100}}>
                          <Image alt="TickImage" src={TickImage} width={25}/>
                        </Col>
                        <Col>
                          <Text css={{textAlign: 'left', marginTop: '5px'}}>Multi Messaging</Text>
                        </Col>
                      </Row>
                    </div>
                  </Container>
                </div>
              </Col>
            </Row>
        </Container>
    </div>
    )
}