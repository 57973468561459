import { Col, Container, Row, Text} from '@nextui-org/react';
import { styles } from '../../styles';
export const Footer = ({isMobile}) => {
    return (
        <>
         <div style={isMobile() ? {marginTop: 0}: {}} />
         <div style={{backgroundColor: '#fff'}}>
            <div style={isMobile() ? {paddingBottom: 30} : {paddingBottom: 200}} id="footer">
                <Container gap={0} display="inline-flex" css={{backgroundColor: '#fff', borderTop: '1px solid #F4F4F4'}}>
                    <Row wrap={isMobile() ? 'wrap' : 'nowrap'}>
                        <Col>
                            <Text h3 color={styles.colors.primary}>Products</Text>
                            <Text h3 css={{fontWeight: '400' }} color={styles.colors.primary}>Features</Text>
                            <Text h3 css={{fontWeight: '400'}} color={styles.colors.primary}>How it works</Text>
                            <Text h3 css={{fontWeight: '400'}} color={styles.colors.primary}>For Agencies</Text>
                            <Text h3 css={{fontWeight: '400'}} color={styles.colors.primary}>Pricing</Text>
                        </Col>
                        <Col>
                            <Text h3 color={styles.colors.primary}>Resources</Text>
                            <Text h3 css={{fontWeight: '400'}} color={styles.colors.primary}>Help Center</Text>
                            <Text h3 css={{fontWeight: '400'}} color={styles.colors.primary}>What's new</Text>
                            <Text h3 css={{fontWeight: '400'}} color={styles.colors.primary}>FAQ's</Text>
                        </Col>
                        <Col>
                            <Text h3 color={styles.colors.primary}>Company</Text>
                            <Text h3 css={{fontWeight: '400'}} color={styles.colors.primary}>About Us</Text>
                            <Text h3 css={{fontWeight: '400'}} color={styles.colors.primary}>Careers</Text>
                            <Text h3 css={{fontWeight: '400'}} color={styles.colors.primary}>Press</Text>
                        </Col>
                        <Col>
                            <Text h3 color={styles.colors.primary}>Social Media</Text>
                            <Text h3 css={{fontWeight: '400'}} color={styles.colors.primary}>Facebook</Text>
                            <Text h3 css={{fontWeight: '400'}} color={styles.colors.primary}>Twitter</Text>
                            <Text h3 css={{fontWeight: '400'}} color={styles.colors.primary}>Linkedin</Text>
                            <Text h3 css={{fontWeight: '400'}} color={styles.colors.primary}>Tiktok</Text>
                        </Col>
                    </Row>
                </Container>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Text small> Renity+ © 2022. All rights reserved</Text>
                    </div>
            </div>
            </div>
        </>
    )
}