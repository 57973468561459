import { GetStartedBgImage, PropertySVGImage, SignUpScreenImage, TenantSVGImage, UserSVGImage} from '../../assets/images';
import { Col, Container, Image, Row, Text} from '@nextui-org/react';
import { styles } from '../../styles';
export const HowItWorks = ({isMobile}) => {
    
    return (
        <div style={{backgroundColor: '#fff'}} id="How-it-works">
            <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            >
                <Text h1 style={{width: 380}} color={styles.colors.primary}>Get started with Renity<i>+</i> in 3 easy steps</Text> <br/>
            </div>
            <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: isMobile ? 30 : 0,
                marginTop: -30,
            }}
            >
            <Text h3 style={{width: 780, fontWeight: '400',}} color={styles.colors.primary}>
                We are building Renity+ to be the most easy to use tool for tenants and landlords. 
                Owning a property business <b>shouldn’t be complicated</b>. </Text>
            </div>
        
            <div style={{
                backgroundImage:`url(${GetStartedBgImage})`,
                backgroundColo: '#fff',
                backgroundRepeat:"no-repeat", 
                backgroundSize:"contain", 
                height:700, 
                backgroundPosition: 'center',
            }}>
            <Container gap={0} display="inline-flex" >
                <Row wrap={isMobile() ? 'wrap' : 'nowrap'} css={isMobile() ? {backgroundColor: '#fff'} : ''}>
                    <Col>
                        <div
                            style={{
                                padding: '2em',
                                margin: '1em',
                                borderRadius: 30,
                                MozBoxShadow: '1px 2px 9px #F4AAB9',
                                boxShadow: '1px 2px 9px #F4AAB9',
                                backgroundColor: '#fff',
                            }}
                        >
                        <Row wrap={isMobile() ? 'wrap' : 'nowrap'}>
                            <Col style={isMobile() ? {} : {width: 200}}>
                            <Image alt="UserSVGImage" src={UserSVGImage} width={80}/>
                            </Col>
                            <Col style={isMobile() ? {textAlign: 'center'} : {textAlign: 'start'}}>
                            <Text h2 color={styles.colors.primary}>Create your account</Text>
                            <Text color={styles.colors.primary}>
                                Create your professional business portfoilo. You will find it easy to import all your previous tenants.
                            </Text>
                            </Col>
                        </Row>
                        </div>
                        <div
                        style={{
                            padding: '2em',
                            margin: '1em',
                            borderRadius: 30,
                            MozBoxShadow: '1px 2px 9px #F4AAB9',
                            boxShadow: '1px 2px 9px #F4AAB9',
                            backgroundColor: '#fff',
                        }}
                        >
                        <Row wrap={isMobile() ? 'wrap' : 'nowrap'}>
                            <Col style={isMobile() ? {} : {width: 200}}>
                            <Image src={PropertySVGImage} width={80}/>
                            </Col>
                            <Col style={isMobile() ? {textAlign: 'center'} : {textAlign: 'start'}}>
                            <Text h2 color={styles.colors.primary}>Create your first property</Text>
                            </Col>
                        </Row>
                        </div>
                        <div
                        style={{
                            padding: '2em',
                            margin: '1em',
                            borderRadius: 30,
                            MozBoxShadow: '1px 2px 9px #F4AAB9',
                            boxShadow: '1px 2px 9px #F4AAB9',
                            backgroundColor: '#fff',
                        }}
                        >
                        <Row wrap={isMobile() ? 'wrap' : 'nowrap'}>
                            <Col style={isMobile() ? {} : {width: 200}}>
                                <Image alt="TenantSVGImage" src={TenantSVGImage} width={80}/>
                            </Col>
                            <Col style={isMobile() ? {textAlign: 'center'} : {textAlign: 'start'}}>
                                <Text h2 color={styles.colors.primary}>Create your tenants</Text>
                            </Col>
                        </Row>
                        </div>
                    </Col>
                    <Col>
                        <Image alt="SignUpScreenImage" src={SignUpScreenImage} width={350}/>
                    </Col>
                </Row>
            </Container>
            </div>
        </div>
    )
}