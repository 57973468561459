export const styles = {
    background: {
        paddingBottom: 40,
        backgroundImage: "linear-gradient(to right, #252B42 30%,#076C67 100%)",
        borderBottomLeftRadius: 200,
    },

    colors: {
        primary: '#252B42',
        secondary: '#0F9992',
        success: '#93cf99',
        warning: '#f7c877',
        danger: '#ff8480',
    },
    text: {
        h1: {
            fontWeight: '500',
            textAlign: 'start',
            color: '#fff',
            light: {
                textAlign: 'start',
                marginTop: -40,
                color: '#fff',
                fontWeight: '200',
            }
        }
    }
};