import { useEffect, useState } from 'react';
import './App.css';
import { Header } from './components';
import {HeroLanding, ThreePointSection, HowItWorks, Footer, Features, Pricing} from './screens';

function App() {  
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
      function handleWindowResize() {
      setWindowSize(window.innerWidth);
  }
      window.addEventListener('resize', handleWindowResize);

      return () => {
      window.removeEventListener('resize', handleWindowResize);
      };
  }, []);

  const isMobile = () => {
      if(windowSize > 650){
          return false;
      }
      return true;
  };
  
  return (
    <div className="App" style={{backgroundImage: "linear-gradient(to right, #252B42 30%,#076C67 100%)"}}>
      <Header isMobile={isMobile} />
      <HeroLanding isMobile={isMobile}/>
      <ThreePointSection isMobile={isMobile}/>
      <HowItWorks isMobile={isMobile}/>
      <Features isMobile={isMobile}/>
      <Pricing isMobile={isMobile} />
      <Footer isMobile={isMobile} />
    </div>
  );
}

export default App;
