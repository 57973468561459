import { Col, Container, Image, Row, Text} from '@nextui-org/react';
import { PortfolioImage, MaintenanceBubbleImage, MessagingBubbleImage, EmergencyBubbleImage, CalendarBubbleImage, SupportBubbleImage} from '../../assets/images';
export const Features = ({isMobile}) => {
    
    return (
        <>
            <div style={isMobile() ? {marginTop: 750}: {}} />
            <div style={{ padding: 20, backgroundColor: '#fff'}} id="Features">
                <Container gap={0}>
                    <Row wrap={isMobile() ? 'wrap' : 'nowrap'} >
                        <Col>
                            <Image alt="PortfolioImage" src={PortfolioImage} width={500}/>
                        </Col>
                        <Col>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                <h1>Features</h1>
                                <Text css={{textAlign: 'left'}} color="#F4AAB9">
                                    Here are some of the features that we have built into our app to give you and your tenants control
                                </Text>
                            </div>
                            <Container gap={0} >
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                    <Row wrap={isMobile() ? 'wrap' : 'nowrap'} >
                                        <Col css={{width: 50, marginRight: 10}}>
                                            <Image alt="MaintenanceBubbleImage" src={MaintenanceBubbleImage} width={50}/>
                                        </Col>
                                        <Col css={{ marginRight: 10,  marginTop: '-15px'}} >
                                            <Text css={{textAlign: 'left'}}><b>Maintenance</b></Text>
                                            <Text color="#F4AAB9" css={{textAlign: 'left', marginTop: -10}}>
                                                Our system quickly handles maintence request for you, so 
                                                you get the issue immediately, and can respond when needed. 
                                                Giving you that professional approach.
                                            </Text>
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                    <Row wrap={isMobile() ? 'wrap' : 'nowrap'} >
                                        <Col css={{width: 50, marginRight: 10}}>
                                            <Image alt="MessagingBubbleImage" src={MessagingBubbleImage} width={50}/>
                                        </Col>
                                        <Col >
                                            <Text css={{textAlign: 'left'}}><b>Multi Messaging</b></Text>
                                            <Text color="#F4AAB9" css={{textAlign: 'left', marginTop: -10}}>
                                                Need to get a message out to all of your tenants at once? Our
                                                App allows you to message indivual tenants or multi message 
                                                them all.
                                            </Text>
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                    <Row wrap={isMobile() ? 'wrap' : 'nowrap'} >
                                        <Col css={{width: 50, marginRight: 10}}>
                                            <Image alt="EmergencyBubbleImage" src={EmergencyBubbleImage} width={50}/>
                                        </Col>
                                        <Col >
                                            <Text css={{textAlign: 'left'}}><b>Emergency Contact</b></Text>
                                            <Text color="#F4AAB9" css={{textAlign: 'left', marginTop: -10}}>
                                                Need a tenants emergency contact details or you’re going on 
                                                hoilday and the tenant has an emergency? You can set this up 
                                                in your property settings. 
                                            </Text>
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                    <Row wrap={isMobile() ? 'wrap' : 'nowrap'} >
                                        <Col css={{width: 50, marginRight: 10}}>
                                            <Image alt="CalendarBubbleImage" src={CalendarBubbleImage} width={50}/>
                                        </Col>
                                        <Col >
                                            <Text css={{textAlign: 'left'}}><b>Rent Reminders</b></Text>
                                            <Text color="#F4AAB9" css={{textAlign: 'left', marginTop: -10}}>
                                                We’ll remind your tenant and you when rent is due, you’ll get a 
                                                notifications for rent being paid and the tenant will get reminded 
                                                when to pay.
                                            </Text>
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                    <Row wrap={isMobile() ? 'wrap' : 'nowrap'} >
                                        <Col css={{width: 50, marginRight: 10}}>
                                            <Image alt="SupportBubbleImage" src={SupportBubbleImage} width={50}/>
                                        </Col>
                                        <Col >
                                            <Text css={{textAlign: 'left'}}><b>24/7 Support</b></Text>
                                            <Text color="#F4AAB9" css={{textAlign: 'left', marginTop: -10}}>
                                                We have teams based in the United Kingdom and Australia. So 
                                                you can be rest assured you will get our friendly customer 
                                                support team at any time.
                                            </Text>
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
    
}