import React from 'react';
import { Col, Container, Image, Row, Text} from '@nextui-org/react';
import { PhoneSVGImage, PersonHandRaisedImage, LockSVGImage} from '../../assets/images';
import { styles } from '../../styles';

export const ThreePointSection = ({isMobile}) => {
    
    return (
        <div style={{marginVertical: 30,  backgroundColor: '#fff'}}>
            <Container gap={0} display="inline-flex">
                <Row wrap={isMobile() ? 'wrap' : 'nowrap'} css={isMobile ? {margin: 30 } : ''}>
                    <Col>
                    <div style={{
                        borderRadius: 20,
                        boxShadow: '1px 2px 9px #F4AAB9',
                        margin: '1em',
                        padding: '2em',
                    }}>
                        <Image alt="PhoneSVGImage" src={PhoneSVGImage} width={80}/>
                        <Text h2 color={styles.colors.primary}><b>Ease of use.</b></Text>
                        <Text css={{textAlign: 'left', color: styles.colors.primary}}>
                        Ease of use in manging and viewing 
                        your property portfolio. <b>It shouldn’t be complicated!</b>
                        </Text>
                    </div>
                    </Col>
                    <Col>
                    <div style={{
                        borderRadius: 20,
                        boxShadow: '1px 2px 9px #F4AAB9',
                        margin: '1em',
                        padding: '2em',
                    }}>
                        <Image alt="PersonHandRaisedImage" src={PersonHandRaisedImage} width={80}/>
                        <Text h2 color={styles.colors.primary}><b>Tenancy made better.</b></Text>
                        <Text css={{textAlign: 'left', color: styles.colors.primary}}>
                        With our system tailoring for tennats
                        and landlords, we make it easier for
                        communication and issues.<b> Theres
                        no bs!</b>
                        </Text>
                    </div>
                    </Col>
                    <Col>
                    <div style={{
                        borderRadius: 20,
                        boxShadow: '1px 2px 9px #F4AAB9',
                        margin: '1em',
                        padding: '2em',
                    }}>
                        <Image alt="LockSVGImage" src={LockSVGImage} height={80}/>
                        <Text h2 color={styles.colors.primary}><b>Total control.</b></Text>
                        <Text css={{textAlign: 'left', color: styles.colors.primary}}>
                        You have total control over your 
                        property, wether its for student housing
                        or long renting. <b>You have control over
                        your portfolio.</b>
                        </Text>
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}