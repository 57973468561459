import LogoImage from './logo.png';
import ProductsImage from './products.png';
import PersonHandRaised from './person_hand_raised.png';
import PhoneSVG from './phone_svg.png';
import LockSVG from './lock_svg.png';
import BBC from './bbc.png';
import NewYorkTimes from './new_york_times.png';
import TheGuardian from './the_guardian.png';
import TechCrunch from './techcrunch.png';
import WSJ from './wsj.png';
import GetStartedBg from './bg.png';
import SignUpScreen from './sign_up_screen.png';
import TenantSVG from './tenant_svg.png';
import PropertySVG from './property_svg.png';
import UserSVG from './user_svg.png';
import Portfolio from './portfolio_image.png';
import MaintenanceBubble from './maintenance_bubble.png';
import MessagingBubble from './messaging_bubble.png';
import EmergencyBubble from './emergency_bubble.png';
import CalendarBubble from './calendar_bubble.png';
import SupportBubble from './support_bubble.png';
import Tick from './tick_green.png';
import X from './x.png';

export const Logo = LogoImage;
export const BannerImage = ProductsImage;
export const PersonHandRaisedImage = PersonHandRaised;
export const PhoneSVGImage = PhoneSVG;
export const LockSVGImage = LockSVG;
export const BBCLogo = BBC;
export const NewYorkTimesLogo = NewYorkTimes;
export const TheGuardianLogo = TheGuardian;
export const TechCrunchLogo = TechCrunch;
export const WSJLogo = WSJ;
export const GetStartedBgImage = GetStartedBg;
export const SignUpScreenImage = SignUpScreen;
export const TenantSVGImage = TenantSVG;
export const PropertySVGImage = PropertySVG;
export const UserSVGImage = UserSVG;
export const PortfolioImage = Portfolio;
export const MaintenanceBubbleImage = MaintenanceBubble;
export const MessagingBubbleImage = MessagingBubble;
export const EmergencyBubbleImage = EmergencyBubble;
export const CalendarBubbleImage = CalendarBubble;
export const SupportBubbleImage = SupportBubble;
export const TickImage = Tick;
export const XImage = X;

