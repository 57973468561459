import { styles } from '../../styles';
import { Text, Container, Row, Col, Image, Button, Link} from "@nextui-org/react";
import { BannerImage } from '../../assets/images';

export const HeroLanding = ({isMobile}) => {
   
    return (
        <div style={styles.background}>
            <Container gap={0} display="inline-flex" id="Home">
                <Row wrap={isMobile() ? 'wrap' : 'nowrap'} css={isMobile ? {margin: 30 } : ''}>
                    <Col >
                        <Text h1 size={isMobile() ? 40 : 60} css={styles.text.h1}>Renity<i style={{fontWeight: 'lighter'}}>+</i></Text>
                        <Text h1 size={isMobile() ? 40 : 60} css={styles.text.h1.light}>Management Made Easy</Text>
                        <Text h2 size={isMobile() ? 20 : 25}css={{textAlign: 'start', color: '#fff'}} weight="light">Take <b>control</b> of your <b>property</b>.</Text>
                        <Text h2 size={isMobile() ? 20 : 25} css={{textAlign: 'start', color: '#fff'}} weight="light">
                            Our easy to use app lets you and your <b>tenants</b> have <b>control</b>, and making your job as a <b>landlord</b> that much easier.
                        </Text>
                        <Button auto as={Link} href="#" css={{
                            backgroundColor: styles.colors.secondary, 
                            color: '#fff', 
                            borderRadius: 25, 
                            fontWeight: '700!important',
                            padding: 22,
                        }}
                        style={{zIndex: '1'}}
                        >
                            Get Early Access
                        </Button>
                    </Col>
                    <Col css={isMobile ? {marginTop: 30 } : ''}>
                        <Image alt="BannerImage" src={BannerImage}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}