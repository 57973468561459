import React from "react";
import { Navbar, Button, Link } from "@nextui-org/react";
import { Logo } from "../../assets/images";
import { styles } from '../../styles';

export const Header = ({isMobile}) => {

  const style = {
    color: '#fff',
    button: {
      backgroundColor: styles.colors.secondary, 
      color: '#fff', 
      borderRadius: 25, 
      fontWeight: '700!important',
      padding: 22,
    }
  }

  const collapseItems = [
    "Home",
    "Features",
    "Pricing",
    "Company",
    "Legal",
    "Team",
    "Help Center",
    "Sign Up",
  ];
    return (
        <Navbar 
          shouldHideOnScroll={isMobile() ? false : true} 
          variant="sticky"
          isBordered
          css={{ background: 'rgba(37, 43, 66, 0.9)!important'}}
          containerCss={{ background: 'transparent!important' }}
          disableBlur
          disableShadow
          >
          <Navbar.Brand>
            <Navbar.Toggle aria-label="toggle navigation" showIn="xs"  css={{ color: '#fff'}} className="burger-menu"/>
            <img alt="Logo" src={Logo} width={50} height={50}/>
          </Navbar.Brand>
          <Navbar.Content>
            <Navbar.Link href="#Home" isActive css={style} hideIn="xs">Home</Navbar.Link>
            <Navbar.Link href="#How-it-works" css={style} hideIn="xs">How it works</Navbar.Link>
            <Navbar.Link href="#Pricing" css={style} hideIn="xs">Pricing</Navbar.Link>
            <Navbar.Link href="#" css={style} hideIn="xs">Help Center</Navbar.Link>
            <Navbar.Item>
              <Button auto as={Link} href="#" css={style.button}>
                Sign Up
              </Button>
            </Navbar.Item>
          </Navbar.Content>
          <Navbar.Collapse css={{ background: 'rgba(37, 43, 66, 0.9)!important' }}>
            {collapseItems.map((item, index) => (
              <Navbar.CollapseItem key={item}>
                <Link
                  css={{
                    color: '#fff!important',
                    minWidth: "100%",
                  }}
                  href={'#' + item} 
                >
                  {item}
                </Link>
              </Navbar.CollapseItem>
            ))}
          </Navbar.Collapse>
        </Navbar>  
      )
}